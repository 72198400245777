<template>
  <div class="footer">
    <h5 @click="click2Gov">
      <!-- Copyright © 2023 Daphina Technology | Created + Designed by Tom | 粤ICP备2022076170号 -->
      Copyright © 2020-2024 | Young Scientist Association (YSA)
    </h5>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods:{
    click2Gov(){
      return
      // window.location.href = 'https://beian.miit.gov.cn'
    }
  }
}
</script>

<style lang="less" scoped>
.footer{
  margin-top: 50px;
  height: 40px;
  color:white;
  background-color: #2836e0;  //#0d1250; 这个配色方案待定！
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 550px) {
  .footer{
    h5{
      font-size: 8px;
    }
  }
}
</style>