<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="paper">
      <el-image v-for="(p, i) in cover" :key="i" 
        :src=p 
        alt=""
        :preview-src-list="all_img"></el-image>
      <!-- <el-image :src=cover[1] alt=""></el-image> -->
    </div>

    <div class="panel">
      <div class="box"
        v-for="(p, i) in paper" :key="i"
        >
        <div class="img" >
          <img :src=article_img[i] alt="">
        </div>
        <h2>{{p.title}}</h2>        
        <div class="author-dl">
          <p class="author">{{p.author}}, 2023/10</p>
          <el-button type="primary" icon="el-icon-download" size="mini" el-icon--right style="padding:0 7px;" @click="clickDownLoadPdf(i)">PDF</el-button>
        </div>
        <p class="abstract">{{p.abstract}}</p>
        <a :href="'https://doi.org/' + p.DOI">{{p.DOI}}</a>
      </div>
    </div>

    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import Footer from "../../components/Footer.vue";

export default {
  name: "Home",
  data() {
    return {
      paper : [
        {
          DOI: "10.5281/zenodo.8048490",
          title: "Experiment on the phototaxis behavior of amphipod (Niphargus sp.)", 
          author: "HouYu Tong",
          abstract:"For many larvae of biofouling organisms, the phototaxis behavior was crucial for their survival as it can help with their mobility, food searching and respond to danger. This experiment tested the phototaxis behavior of the adult of Niphargus sp. amphipod collected from Dapeng Shenzhen and compared it to the phototaxis behavior of the larvae of subtidal acorn barnacle in the genus Balanus. Result using four different wavelength of light shows that Niphargus sp. can sense and respond to lasers of 635, 532, 515 and 445 with negative phototaxis, very different from the phototaxis behavior of barnacle nauplius larvae. Despite both being in the same biofouling community, amphipod have more advance eye structure as well as more complex behavior than barnacle. "
        }, 
        {
          DOI: "10.5281/zenodo.8048448",
          title: "Database construction on planktons and biofouling organisms",
          author: "Diarmuid Chu1 et al.",
          abstract:"Serotonin is a neurotransmitter that has a profound impact on mood and hunger. It is a typical “happy hormone” that is used to treat depression and can also suppress appetite. The study is set to determine the difference in foraging behavior of two planarians, Paucumara sp. (marine water species) and Dugesia sp. (freshwater species) in the presence and absence of Serotonin. The two species are placed in two different concentrations of serotonin, and pork liver paste is used as a food source to attract the two species. Their corresponding foraging behavior is observed and noted. The results of the experiment showed that there is a positive correlation between foraging behavior and concentration of serotonin for Paucumata sp. but not for Dugesia sp."
        },
        {
          DOI: "10.5281/zenodo.8048463",
          title: "An effective pre-processing 9of NeRF 3D reconstruction for benthic target", 
          author: "Lyuyongkang Yuan",
          abstract: "The nearshore oceans, home to rich benthic ecosystems, remain an area of significant research interest. While 2D visual representations have been the mainstay in this area, the intricate, multi-dimensional nature of the seafloor ecosystems underscores the need for 3D modeling to capture their full essence.This research introduces a methodology tailored for static image processing and 3D modeling using Neural Radiance Fields (NeRF), specifically the optimized instantNGP variant. A streamlined pipeline has been developed, focusing on mitigating the visual challenges posed by light interference and seawater in underwater imaging. This pre-processing approach effectively prepares images for the NeRF-based 3D reconstruction without an excessive computational burden. Visual enhancements successfully corrected color imbalances in underwater images, addressing the common blue-green tint caused by light conditions. "
        },
        {
          DOI: "10.5281/zenodo.8048471",
          title: "The study of microplastic imaging in the anatomy of zooplankton based on fluorescent staining", 
          author: "HouYu Tong",
          abstract:"Because of its geographical location, Shenzhen Bay contains a great number of deposits and pollution both from itself and the upper stream. One of the marine pollutants in Shenzhen Bay is microplastic particles. In such an environment, the accumulation of microplastic particles in zooplankton is essential to the health of the population due to the process of bioaccumulation. In tradition, microplastic particles are measured in plankton by removing all organic matter. This experiment aims to use an alternative method of fluorescent imaging to reveal the existence and distribution of microplastics within zooplankton from Shenzhen Bay. Triton X-100 was added without the process of fixation using paraformaldehyde to remove lipids and keep potential MPs and other anatomy of the sample. "
        },
        {
          DOI: "10.5281/zenodo.8048479",
          title: "Documentation of Actinopterygii (ray-finned fish), and Decapoda (Crabs and Shrimps) found in Dapeng, Shenzhen", 
          author: "Daniel K. H. Yu",
          abstract:"Dapeng is a district located in the easternmost extremity of Shenzhen and to the northeast of Hong Kong. We went to a fish farm raft located in Dapeng to collect specimens and observe the district’s biodiversity. In order to assess the impact of human activity on the local environment, I decided to launch a complete investigation and guide to the marine organisms inhabiting the area, observing what species have adapted to this polluted environment."
        },
        {
          DOI: "10.5281/zenodo.8048488",
          title: "The effects of serotonin on regeneration of the planarian paucumara falcata", 
          author: "Rose Xu",
          abstract:"Planarians, for their efficient regeneration capacity and establishment of a central nervous system, are suitable models for understanding stem cells potency and developmental neuroscience. The ubiquitous neurotransmitter, serotonin, was stated to have a vital role in the regeneration process. This research investigated the regenerative sexual reproduction effect of exogenous serotonin in a recently found marine planarian, Paucumara falcata. Contrary to freshwater species, this marine planarian showed dose-dependent retarded regeneration in presence of serotonin, suggesting possible variation in the species’ serotoninergic pathways; serotonin was shown to stimulate the production of cocoons in planarian."
        },
        {
          DOI: "10.5281/zenodo.8052488",
          title: "The enigmatic interna: Unravelling the intricacies of parasitic barnacles and their host’s internal environment", 
          author: "Hailey Wang",
          abstract:"Parasites are important to study as they help us understand diseases of organisms and find corresponding treatments. While there are many diseases associated with crustaceans, rhizocephalans, or parasitic barnacles, are one of the most peculiar problems of decapods, which is known as crabs. Their features including their life cycle, host manipulation, evolution is all very different from what have been known now. In this study, we used fluorescent staining to evaluate the histological structures of interna of parasitic barnacles. From the study, we have discovered that the parasitic barnacle interna does not grow through all the organs and tissues within the host; Besides, we also concluded that parasitic barnacle interna is hollow and it has a tube-like structure; Moreover, infected crabs appear to be significantly different from normal crabs. Hence, these tells us that the features of parasitic barnacles are extremely different from other types of barnacles, reflecting rhizocephalans’ uniqueness."
        },
        {
          DOI: "10.5281/zenodo.8048498",
          title: "Photo Gallery of Coral Summer Camp, Hong Kong, 2023",
          author: "Kate Lin",
          abstract:"A common benthic organism was found attaching to different submerged structures, such as ship hull, oil platform, fishing net etc, deeming the species one of the most common biofouling organisms worldwide. Despite having a highly calcified shell to protect its body, barnacles are crustaceans that are sister to decapod crustaceans such as shrimps and crabs.  The portraited specimen was collected from a fish farm raft on the Lamma Island, Hong Kong."
        },
      ],

      cover: [
        require('../../assets/img/ysa/v2i1/i2_cover.jpg'),
        require('../../assets/img/ysa/v2i1/martin_to_journal.jpg'),
      ],

      article_img:[
        require('../../assets/img/ysa/v2i1/v2i1a1.jpg'),
        require('../../assets/img/ysa/v2i1/v2i1a2.jpg'),
        require('../../assets/img/ysa/v2i1/v2i1a3.jpg'),
        require('../../assets/img/ysa/v2i1/v2i1a4.jpg'),
        require('../../assets/img/ysa/v2i1/v2i1a5.jpg'),
        require('../../assets/img/ysa/v2i1/v2i1a6.jpg'),
        require('../../assets/img/ysa/v2i1/v2i1a7.jpg'),
        require('../../assets/img/ysa/v2i1/v2i1a8.jpg'),
      ],
      
      all_img : [],

      preface: [
        'Charles Darwin is considered one of the most influential scientists in the field of biology. He begun working on the topic of evolution in 1837, drawn up "some short notes" after five years, enlarged these into a sketch in 1844, and finally published the masterpiece “On the Origin of Species” on 24 November 1859. Darwin’s experience has three messages. Firstly, curiosity toward nature, as the development of his evolutionary theories started with his long observations on a wide range of organism species. Secondly, successive concentration and passion on a topic, as it took him more than 20 years to perfect his evolutionary theories. Finally, good scientific works should be supported by a body of scientific evidence.',
        'It is therefore our wish that young students would express curiosity toward nature, eager to observe, dare to ask why, and more importantly stay on the topic and find out the answer. We see The Young Scientist as a platform for students to be young scientists. As a group of active scientific researchers, we hope that by publishing decent scientific works conducted by students, we can encourage more of you to join us, doing interesting experiments, using new technologies and help us to extract novel but critical findings that would have been failed to be discovered using traditional methods.',
        'We believe the greatest scientists of the next century are among you!',
      ],
      preface_note: "Editorial Board of The Young Scientist",
    };
  },    
  components: {
    Footer,
    Header,
    // SectTurnBook,
  },

  mounted() {
    this.all_img = this.cover.concat(this.article_img);
  },

  methods: {
    clickDownLoadPdf(i){
      // console.log(i)
      window.location.href ='../public/ysa_v2i1a' + (i+1) + '.pdf' ;
    }
  }
}

</script>

<style lang="less" scoped>
.panel {
  // margin-bottom: 40px;
  width: 1200px;
  margin: 20px auto;
  columns: 4;
  column-gap: 40px;
  // overflow: hidden;
}
.panel .box {
  width: 100%;
  margin: 0 0 20px;
  padding: 10px;
  background: #fff;
  // overflow: hidden;
  // break-inside: avoid;
}
.panel .box img {
  max-width: 100%;
}
.panel .box h2 {
  margin: 10px 0 0;
  padding: 0;
  font-size: 18px;
}
.panel .box p {
  margin: 10px 0 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.2em;
  transition: 0.5s;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
}
.author-dl {
  display: flex;
  justify-content:space-between
}
.author {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 900;
    color: #005eff;
  }
.abstract::before {
  content:  "Abstract. ";
  font-weight: 900;
}
.paper {
  text-align: center;
  .el-image {
    max-width: 600px;
    width:40%;
    margin: 10px 5px;
  }
}
@media (max-width: 1200px) {
  .panel {
    columns: 3;
    width: calc(100% - 40PX);
    box-sizing: border-box;
    padding: 20px 20px 20px 0;
  }
}
@media (max-width: 768px) {
  .panel {
    columns: 2;
  }
}
@media (max-width: 480px) {
  .panel {
    columns: 1;
  }
}
</style>
